<script>
import ContentForm from "@/views/content/contentForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    contentService: "contentService",
    fileUploadService: "fileUploadService",
    visitorLevelService: "visitorLevelService"
  },
})
export default class ContentEdit extends mixins(ContentForm) {
  formId = "edit-content-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      const idParent = this.model.idParent;
      const type = this.model.type;
        await this.contentService.update(this.model.id, this.model);
      this.$router.push(`/content/list/${idParent}/${type}`);
      toastService.success(this.translations.success.content_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.contents_edit;
  }

  async created() {
    this.model = await this.contentService.read(this.$route.params.id);
    if (this.$route.params.type !== this.model.type) {
      throw(`This content is of type "${this.model.type}"; "${this.$route.params.type}" expected`);
    }

    this.setVisitorLevels(await this.visitorLevelService.enabledLevels(this.model.id));
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.isReady = true;
  }
}
</script>
